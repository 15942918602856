<template>
  <!-- navigation bar слева -->
  <div>
    <v-dialog v-model="showDialog" max-width="600">
  <v-card>
    <v-card-title>
      <span class="headline">Ограниченный доступ</span>
    </v-card-title>
    <v-card-text>
      <p>
        Вы находитесь в стартовом режиме приложения. Некоторые функции могут
        быть недоступны.
      </p>
      <p>
        Чтобы получить доступ ко всем возможностям, пожалуйста, обновите вашу
        подписку.
      </p>
      <p>
        Свяжитесь с нашей службой поддержки или перейдите в раздел "Мой аккаунт"
        для получения информации об обновлении.
      </p>

      <!-- Поле ввода номера телефона -->
      <v-text-field
        v-if="phoneInputVisible"
        v-model="phoneNumber"
        label="Введите номер телефона"
        outlined
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#FFA500" text @click="showDialog = false">Закрыть</v-btn>
      <v-btn color="#FFA500" style="color: white;" @click="handleRequestAccess">
        {{ phoneInputVisible ? "Отправить номер" : "Запросить доступ" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :expand-on-hover="expandOnHover" :dark="true"
      :right="$vuetify.rtl" src="https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg"
      mobile-breakpoint="960" app width="260" v-bind="$attrs">
      <template v-slot:img="props">
        <v-img :gradient="`to bottom, rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)`" v-bind="props" />
      </template>

      <v-divider class="mb-1" />

      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="display-1 font-sans-pro">
              <strong class="font-sans-pro" style="color: orange">
                Easy
              </strong>
              <strong class="font-sans-pro" style="color: white">
                book
              </strong>
            </v-list-item-title>
            <strong v-if="GetCompanyName" class="mt-3 font-sans-pro font-weight-bold" style="color: white; font-size: large;"> 
              {{ GetCompanyName }}
            </strong>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-2" />
      <v-list v-if="!isFoodTickerManager" expand nav>
        <div />

        <template v-for="(item, i) in computedItems">
          <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" />
          <div v-else :key="i">
            <base-item v-if="perm(item) && permObjType(item)" :key="`item-${i}`" :item="item" />
          </div>
        </template>
        <div />
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { FOOD_TICKET_MANAGER } from "@/shared/config";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import user from "../../../../store/auth";
import toast from '../../../../plugins/toast';
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showDialog: false,
    phoneInputVisible: false, 
    phoneNumber: "", 
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Календарь брони",
        group: "/",
        to: "/",
        perm: ["admin", "merchant", "merchant_admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-list-status",
        title: "Состояние броней",
        group: "/",
        to: "/orders",
        perm: ["admin", "merchant", "merchant_admin"],
        object_type: ["simple"]
      },
      {
        icon: "mdi-file-chart",
        title: "Отчеты",
        group: "/",
        to: "/regular-tables",
        perm: ["admin", "merchant_admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-file-chart",
        title: "Отчеты ПНЛ",
        group: "/",
        to: "/reports",
        perm: ["admin", "merchant_admin"],
        object_type: ["simple"]
      },
      {
        icon: "mdi-connection",
        title: "Задачи",
        to: "/tasks",
        perm: ["admin", "merchant", "merchant_admin"],
        object_type: ["simple"]
      },
      {
        icon: "mdi-folder-multiple",
        title: "Журнал действий",
        to: "/journal",
        perm: ["admin", "merchant_admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-list-status",
        title: "Список броней",
        to: "/listBookings",
        perm: ["admin", "merchant", "merchant_admin"],
        object_type: ["is_leisure"]
      },
      {
        icon: "mdi-cog-outline",
        title: "Настройки",
        group: "settings",
        perm: ["admin", "merchant", "merchant_admin"],
        object_type: ["is_leisure", "simple"],
        children: [
          {
            title: "Профиль",
            icon: "mdi-minus",
            to: "/profile",
            perm: ["admin", "merchant", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Информация об обьекте",
            icon: "mdi-minus",
            to: "/object-info",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Категории",
            icon: "mdi-minus",
            to: "/categories",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Связка номеров",
            icon: "mdi-minus",
            to: "/mapping",
            perm: ["admin", "merchant", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Номера",
            icon: "mdi-minus",
            to: "/rooms",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Питание",
            icon: "mdi-minus",
            to: "/food-tariffs",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Настройка тарифов",
            icon: "mdi-minus",
            to: "/tariffs",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Настройка тарифов",
            icon: "mdi-minus",
            to: "/tariffsLeisure",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure"]
          },
          {
            title: "Доп. тарифы",
            icon: "mdi-minus",
            to: "/additional-tariffs",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Cоздание статей",
            icon: "mdi-minus",
            to: "/states",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Расходы",
            icon: "mdi-minus",
            to: "/expenditure",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Сотрудники",
            icon: "mdi-minus",
            to: "/employees",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Услуги",
            icon: "mdi-minus",
            to: "/services",
            perm: ["admin", "merchant_admin"],
            object_type: ["simple"]
          },
          {
            title: "Скидки и купоны",
            icon: "mdi-minus",
            to: "/discount_list",
            perm: ["admin", "merchant_admin"],
            object_type: ["is_leisure", "simple"]
          },
          {
            title: "Виды оплат",
            icon: "mdi-minus",
            to: "/payment_types",
            perm: ["admin", "merchant", "merchant_admin"],
            object_type: ["simple"]
          }
        ]
      },
      {
        icon: "mdi-point-of-sale",
        title: "Каналы продаж",
        to: "/list",
        perm: ["admin", "merchant_admin"],
        object_type: ["simple"]
      },
      {
        icon: "mdi-point-of-sale",
        title: "Источник продаж",
        to: "/source",
        perm: ["admin", "merchant_admin"],
        object_type: ["is_leisure"]
      },
      {
        icon: "mdi-view-module",
        title: "Модуль бронирования",
        to: "/booking-module",
        perm: ["admin", "merchant_admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-view-module",
        title: "Компания",
        to: "/companies",
        perm: ["admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-google-maps",
        title: "Местность",
        to: "/location",
        perm: ["admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-food-fork-drink",
        title: "Еда",
        to: "/food",
        perm: ["admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-star",
        title: "Популярность",
        to: "/popular",
        perm: ["admin"],
        object_type: ["is_leisure", "simple"]
      },
      {
        icon: "mdi-parachute",
        title: "Тип активного отдыха",
        to: "/leisureType",
        perm: ["admin"],
        object_type: ["is_leisure", "simple"]
      }
    ],
    premium_list: [
      {
        name: "Отчеты",
        path: "regular-tables",
        component: () => import("@/views/main/tables/Details")
      },
      {
        name: "Отчеты ПНЛ",
        path: "reports",
        component: () => import("@/views/main/pages/Reports")
      },
    ],
    isFoodTickerManager: false,
    companyName: null
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    GetCompanyId() {
      return user.getters.getCompany
    },
    GetCompanyName() {
      return user.getters.getCompanyName
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    getHasWidget() {
      return user.state.has_widget;
    },
    getUserRole() {
      return user.getters.getUserRole;
    }
  },
  watch: {
    getHasWidget() {
      this.items = this.items.map(el => {
        if (el.title === "bookingModule") {
          el.disabled = this.getHasWidget !== "true";
        }
        return el;
      });
    },
    "$route.name"() {
      if (this.getUserRole === FOOD_TICKET_MANAGER) {
        this.isFoodTickerManager = true;
      }
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.path === "/doesntaccess") {
        this.showDialog = true;
        next(false);
      } else {
        next(); // Позволяем переход
      }
    });
    if (this.getUserRole === FOOD_TICKET_MANAGER) {
      this.isFoodTickerManager = true;
    }
    this.items.map(el => {
      if (el.title === "bookingModule") {
        el.disabled = this.getHasWidget !== "true";
      }
    });
  },
  methods: {
    async handleRequestAccess() {
      if (!this.phoneInputVisible) {
        this.phoneInputVisible = true;
      } else {
        if (!this.phoneNumber) {
          toast.error("Введите номер телефона!");
          return;
        }

        try {
          let companyId = user.getters.getCompany;
          let resp = await CompaniesApiUrls.changeTarif(
            companyId,
            this.phoneNumber);
          console.log("Response:", resp);
          toast.success("Запрос отправлен успешно!");
          this.showDialog = false; // Закрываем диалоговое окно
        } catch (error) {
          console.error("Error:", error);
          toast.error("Произошла ошибка. Попробуйте ещё раз.");
        }
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : "",
        title: item.title
      };
    },
    permObjType(item) {
      if (item.object_type.includes(user.state.object_type)) {
        return true;
      }
      return false;
    },
    perm(item) {
      for (const i in item.perm) {
        if (item.perm.includes(user.state.role)) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
strong
  font-size: 26px
.v-list-item__title
  overflow: visible !important
#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>

<style lang="sass" scoped>
.v-list-item
  display: flex !important
</style>
<style>
@font-face {
  font-family: "PF Square Sans Pro";
  src: url("../../../../fonts/PFSquareSansPro-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

.font-sans-pro {
  font-family: "PF Square Sans Pro", serif;
  font-weight: bold;
  font-style: normal;
}
</style>
