<template>
    <div class="access-restricted">
        <v-container class="text-center">
          <v-row>
            <v-col>
              <h2 class="headline">Страница не найдена  <v-icon large>mdi-alert-circle-outline</v-icon>  </h2>
              <p>
                Страница которую вы запрашиваете, не существует или удалена. Перейдите в главное меню по ссылке внизу.
              </p>
              <v-btn elevation="0" outlined  color="#FFA500" @click="goHome">Главная страница</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
  </template>
  
  <script>
  export default {
    methods: {
      goHome() {
        this.$router.push('/'); 
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .text-center {
    text-align: center;
  }
  </style>
  