<template>
  <div>
    <v-container v-if="$route.params.id">
      <v-row :align="'center'" :justify="'center'">
        <v-stepper v-model="e1" style="min-width: 900px; max-width: 900px">
          <v-stepper-header>
            <v-stepper-step @click="e1 = 1" step="1" style="cursor: pointer">
              Данные
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="e1 > 2" @click="e1 = 2" step="2" style="cursor: pointer">
              Пароль
            </v-stepper-step>
            <v-divider />
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col class="pt-2 pb-0" cols="4" md="4">
                  <v-text-field v-model="first_name_update" dense label="Фамилия" />
                </v-col>
                <v-col class="pt-2 pb-0" cols="4" md="4">
                  <v-text-field v-model="last_name_update" dense label="Имя" />
                </v-col>
                <v-col class="pt-2 pb-0" cols="4" md="4">
                  <v-text-field v-model="middle_name_update" dense label="Отчество" />
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="12">
                  <v-text-field v-model="role_update" dense label="Должность" :disabled="!isSuperUser" />
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="12">
                  <v-text-field dense label="E-mail" v-model="email_update" />
                </v-col>
                <v-col class="pt-0 pb-0" cols="12" md="12">
                  <v-select label="Статус" :items="['Premium', 'Starter']" ></v-select>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="saveFirst" :disabled="disabledBtn">
                Сохранить
              </v-btn>
              <v-btn text @click="close"> Выход </v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form class="mt-3" v-model="valid">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" md="12">
                    <v-text-field v-model="password_update" dense label="Новый пароль" type="password" />
                  </v-col>
                  <v-col class="pt-0 mt-2 pb-0" cols="12" md="12">
                    <v-text-field v-model="repeat_password_update" dense label="Подтвердить пароль" type="password" />
                  </v-col>
                </v-row>
                <v-btn color="primary" @click="changePassword" :disabled="disabledBtn">
                  Сохранить
                </v-btn>
                <v-btn text to="/"> Выход </v-btn>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-row>
    </v-container>
    <v-form v-else v-model="valid">
      <v-container>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-row :align="'center'" :justify="'center'">
              <v-col cols="6" md="6">
                <v-text-field ref="last_name" v-model="item.last_name" dense label="Фамилия"
                  :rules="[() => !!item.last_name]" />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field ref="first_name" v-model="item.first_name" dense label="Имя"
                  :rules="[() => !!item.first_name]" />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field ref="middle_name" v-model="item.middle_name" dense label="Отчество"
                  :rules="[() => !!item.middle_name]" />
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field ref="email" v-model="item.email" dense label="E-mail" :rules="emailRules" />
              </v-col>
              <v-col cols="6" md="6">
                <v-select v-if="isSuperUser" ref="role" v-model="role" dense label="Роль" :items="roles"
                  :rules="[() => !!role]" />
                <v-select v-else ref="role" v-model="role" dense label="Роль" :items="roleTexts"
                  :rules="[() => !!role]" />
              </v-col>
              <v-col>
                <v-text-field ref="password" v-model="item.password" dense label="Пароль" type="password"
                  :rules="[() => !!item.password]" />
              </v-col>
              <v-col>
                <v-text-field ref="repeat_password" v-model="item.repeat_password" dense label="Повторите пароль"
                  type="password" :rules="[() => !!item.repeat_password]" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="close"> Отменить </v-btn>
            <v-btn color="primary" text @click="save" :disabled="disabledBtn">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { UsersApiUrls } from "@/services/accommodationRequests/user.api.js";
import { FOOD_TICKET_MANAGER } from "@/shared/config";

import user from "../../../store/auth";
import toast from "../../../plugins/toast";

export default {
  name: "Employer",
  data() {
    return {
      disabledBtn: false,
      id: null,
      first_name_update: null,
      middle_name_update: null,
      last_name_update: null,
      role_update: null,
      email_update: null,
      password_update: null,
      status_update: null,
      repeat_password_update: null,
      e1: 1,
      emailRules: [
        v => !!v,
        v => /.+@.+\..+/.test(v) || "Введите правильный email"
      ],
      valid: false,
      roles: [
        { text: "Суперюзер", value: "admin" },
        { text: "Админ", value: "merchant_admin" },
        { text: "Сотрудник", value: "merchant" },
        { text: "Сотрудник QR", value: FOOD_TICKET_MANAGER }
      ],
      roleTexts: [
        { text: "Админ", value: "merchant_admin" },
        { text: "Сотрудник", value: "merchant" },
        { text: "Сотрудник QR", value: FOOD_TICKET_MANAGER }
      ],
      role_text: null,
      item: {
        first_name: null,
        last_name: null,
        middle_name: null,
        email: null,
        is_superuser: false,
        is_admin: false,
        is_staff: true,
        repeat_password: null,
        password: null,
        company: Number(user.getters.getCompany),
        application: "easybook"
      },
      role: null,
      isSuperUser: false
    };
  },
  validations: {
    item: {
      last_name: { required },
      first_name: { required },
      email: { required },
      middle_name: { required },
      password: { required },
      repeat_password: { required }
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    }
  },
  mounted() {
    if (user.getters.getIsSuperuser === "true") {
      this.isSuperUser = true;
    }
  },
  async created() {
    if (this.$route.params.id) {
      let res = await UsersApiUrls.getEmployee(this.$route.params.id);
      this.first_name_update = res.first_name;
      this.last_name_update = res.last_name;
      this.middle_name_update = res.middle_name;
      this.email_update = res.email;
      this.id = res.id;
      this.role_update = this.roles.find(e => e.value == res.user_role).text;
    }
  },
  methods: {
    async saveFirst() {
      this.disabledBtn = true;
      let body = {
        email: this.email_update,
        first_name: this.first_name_update,
        last_name: this.last_name_update,
        middle_name: this.middle_name_update,
        company: Number(user.getters.getCompany),
        user_role: this.roles.find(e => e.text == this.role_update).value
      };
      try {
        await UsersApiUrls.setEmployee(this.$route.params.id, body);
        this.disabledBtn = false;
        toast.success("Успешно изменено!");
        this.close();
      } catch (e) {
        this.disabledBtn = false;
      }
    },
    async changePassword() {
      this.disabledBtn = true;
      if (this.password_update != this.repeat_password_update) {
        toast.error("Пароли должны быть одинаковыми!");
        return;
      }
      let body = {
        password: this.password_update
      };
      try {
        await UsersApiUrls.setEmployee(this.$route.params.id, body);
        this.disabledBtn = false;
        toast.success("Успешно изменено!");
        this.close();
      } catch (e) {
        this.disabledBtn = false;
      }
    },
    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        Object.keys(this.item).forEach(f => {
          if (this.$refs[f]) {
            this.$refs[f].validate(true);
          }
        });
        if (!this.role) {
          this.$refs.role.validate(true);
        }
        return;
      }
      if (this.item.password != this.item.repeat_password) {
        toast.error("Пароли не совпадают");
        return;
      }
      this.item = { ...this.item, user_role: this.role };
      this.disabledBtn = true;
      try {
        await UsersApiUrls.createEmployee(this.item);
        this.disabledBtn = false;
        toast.success("Успешно создано!");
        this.close();
      } catch (e) {
        this.disabledBtn = false;
      }
    },
    close() {
      this.$router.push("/employees");
    }
  }
};
</script>

<style scoped></style>
