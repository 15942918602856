<template>
  <v-container>
    <v-card class="px-2 px-md-5 py-md-3 py-1">
      <v-col cols="12">
        <code ref="textToCopy">
          {{ iframe }}
        </code>
      </v-col>
      <v-col cols="12">
        <v-col cols="12" md="3" class="d-inline-block">
          <v-btn style="width:100%;" @click="copy"> Скопировать </v-btn>
        </v-col>
        <v-col cols="12" md="3" class="d-inline-block">
          <v-btn style="width:100%;" @click="showWidget">
            Показать виджет
          </v-btn>
        </v-col>

        <v-dialog
          v-model="dialog_color"
          hide-overlay
          max-width="1200"
          transition="dialog_a-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col cols="12" md="3" class="d-inline-block">
              <v-btn style="width:100%;" v-bind="attrs" v-on="on">
                Выбрать цвета
              </v-btn>
            </v-col>
          </template>

          <v-card>
            <v-toolbar>
              <v-toolbar-title>Выбрать цвета</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-row no-gutters>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile>
                  <p class="justify-center mt-3">Выберите цвет фона</p>
                  <v-color-picker
                    v-model="background_color"
                    class="mt-5"
                  ></v-color-picker>
                  <pre>{{ background_color }}</pre>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile>
                  <p class="justify-center mt-3">Выберите основной цвет</p>
                  <v-color-picker
                    v-model="primary_color"
                    class="mt-5"
                  ></v-color-picker>
                  <pre>{{ primary_color }}</pre>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile>
                  <p class="justify-center mt-3">Выберите доп. цвет</p>
                  <v-color-picker
                    v-model="accent_color"
                    class="mt-5"
                  ></v-color-picker>
                  <pre>{{ accent_color }}</pre>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile>
                  <p class="justify-center mt-3">Выберите цвет текста</p>
                  <v-color-picker
                    v-model="text_color"
                    class="mt-5"
                  ></v-color-picker>
                  <pre>{{ text_color }}</pre>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2" outlined tile>
                  <p class="justify-center mt-3">Выберите цвет текста кнопок</p>
                  <v-color-picker
                    v-model="text_btn_color"
                    class="mt-5"
                  ></v-color-picker>
                  <pre>{{ text_btn_color }}</pre>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-row class="justify-center">
                  <v-btn
                    class="my-5"
                    color="warning"
                    @click="saveColors"
                    :disabled="disabledBtn"
                    >Сохранить цвета</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog_certificate"
          hide-overlay
          max-width="600px"
          transition="dialog_a-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col cols="12" md="3" class="d-inline-block">
              <v-btn style="width:100%" v-bind="attrs" v-on="on">
                Добавить сертификаты
              </v-btn>
            </v-col>
          </template>

          <v-card>
            <v-toolbar>
              <v-toolbar-title>Выбрать сертификат</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-row no-gutters style="padding: 10px 30px">
              <v-form ref="form" style="background-color: white; width: 100%">
                <v-text-field
                  v-model="certificate_id"
                  label="Certificate id"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="merchant_name"
                  label="Merchant name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="merchant_id"
                  label="Merchant id"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="private_key"
                  label="Private key"
                  required
                ></v-text-field>

                <v-file-input
                  accept=".pub"
                  label="Public key fn"
                  v-model="public_file"
                ></v-file-input>

                <v-file-input
                  accept=".prv"
                  label="Private key fn"
                  v-model="private_file"
                ></v-file-input>

                <v-row class="justify-center" style="padding: 10px 0">
                  <v-btn
                    :disabled="disabledBtn"
                    color="warning"
                    class="mr-4"
                    @click="saveCertificate"
                  >
                    Сохранить сертификат
                  </v-btn>
                </v-row>
              </v-form>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-card>

    <v-card class="px-2 px-md-5 py-md-3 py-1">
      <v-col cols="12">
        <h2 class="mb-3">Партнерская ссылка</h2>
        <code ref="textToCopy">
          {{ iframe2 }}
        </code>
      </v-col>
      <v-col cols="12">
        <v-col cols="12" md="3" class="d-inline-block">
          <v-btn style="width:100%;" @click="copy_PartnerLink"> Скопировать </v-btn>
        </v-col> 
        <v-col cols="12" md="3" class="d-inline-block">
          <v-btn style="width:100%;" @click="ShowPartner_link"> Посмотреть </v-btn>
        </v-col> 
      </v-col>
    </v-card>

    <v-card v-if="show">
      <vue-widget title="Vuidget live example"></vue-widget>
    </v-card>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import { WidgetApiUrls } from "@/services/accommodationRequests/widget.api.js";
import { CompaniesApiUrls } from "../../../services/accommodationRequests/companies.api";
export default {
  data() {
    return {
      disabledBtn: false,

      certificate_id: "",
      merchant_name: "",
      merchant_id: "",
      private_key: "",
      private_file: [],
      public_file: [],

      show: false,
      iframe: "",
      iframe2: "", // partner_link
      company_data: {},
      colorPicker: false,
      dialog_color: false,
      dialog_certificate: false,
      back_color: "#FF00FF",
      prim_color: "#FF00FF",
      acc_color: "#FF00FF",
      txt_color: "#FF00FF",
      txt_btn_color: "#FF00FF",
      companySlug: ""
    };
  },
  computed: {
    background_color: {
      get() {
        return this["back_color"];
      },
      set(v) {
        this["back_color"] = v;
      }
    },
    primary_color: {
      get() {
        return this["prim_color"];
      },
      set(v) {
        this["prim_color"] = v;
      }
    },
    accent_color: {
      get() {
        return this["acc_color"];
      },
      set(v) {
        this["acc_color"] = v;
      }
    },
    text_color: {
      get() {
        return this["txt_color"];
      },
      set(v) {
        this["txt_color"] = v;
      }
    },
    text_btn_color: {
      get() {
        return this["txt_btn_color"];
      },
      set(v) {
        this["txt_btn_color"] = v;
      }
    },
    showBackgroundColor() {
      return this.background_color.back_color;
    },
    showPrimaryColor() {
      return this.primary_color.prim_color;
    },
    showAccentColor() {
      return this.accent_color.acc_color;
    },
    showTextColor() {
      return this.text_color.txt_color;
    },
    showTextBtnColor() {
      return this.text_btn_color.txt_btn_color;
    }
  },
  async mounted() {
    try {
      let resp = await WidgetApiUrls.getColors();
      this.company_data = resp;
      this.back_color = resp.background_color;
      this.prim_color = resp.primary_color;
      this.acc_color = resp.accent_color;
      this.txt_color = resp.text_color;
      this.txt_btn_color = resp.btn_text_color;
      this.iframe = `<script type="text\/javascript">localStorage.setItem("api_url", "${resp.api_url}");localStorage.setItem("id_company", "${resp.company_id}");localStorage.setItem("background", "${resp.background_color}"); localStorage.setItem("primary", "${resp.primary_color}"); localStorage.setItem("accent", "${resp.accent_color}"); localStorage.setItem("text", "${resp.text_color}"); localStorage.setItem("text_button", "${resp.btn_text_color}" ); <\/script> <vue-widget title="Vuidget live example"><\/vue-widget>\n
      <script src="${resp.api_url}\/widget\/app.js" type="text\/javascript"><\/script>\n
      <link rel="stylesheet" href="${resp.api_url}\/widget\/app.css">`;
      localStorage.setItem("id_company", this.company_data.company_id);
      localStorage.setItem("background", this.company_data.background_color);
      localStorage.setItem("primary", this.company_data.primary_color);
      localStorage.setItem("accent", this.company_data.accent_color);
      localStorage.setItem("text", this.company_data.text_color);
      localStorage.setItem("text_button", this.company_data.btn_text_color);
      localStorage.setItem("api_url", this.company_data.api_url);
      
      let companySlug = localStorage.getItem("slug")
      this.iframe2 = `https://${companySlug}.go2trip.kz/`

      let script1 = document.createElement("script");
      script1.src = `${this.company_data.api_url}/widget/app.js`;
      script1.defer = true;
      script1.type = "text/javascript";
      document.head.appendChild(script1);

      // let style1 = document.createElement("link");
      // style1.href = `${this.company_data.api_url}/widget/app.css`;
      // style1.rel = "stylesheet";
      // document.head.appendChild(style1);
    } catch (e) {
      toast.error("Подключите виджет");
    }
  },
  methods: {
    async saveCertificate() {
      if (
        this.certificate_id.length == 0 ||
        this.merchant_name.length == 0 ||
        this.merchant_id.length == 0 ||
        this.private_key.length == 0 ||
        this.private_file.size == 0 ||
        this.public_file.size == 0
      ) {
        toast.error("Заполните данные");
        return;
      }
      let jwt = localStorage.getItem("token");
      var formdata = new FormData();
      formdata.append("certificate_id", this.certificate_id);
      formdata.append("merchant_name", this.merchant_name);
      formdata.append("merchant_id", this.merchant_id);
      formdata.append("public_key_fn", this.public_file);
      formdata.append("private_key_fn", this.private_file);
      formdata.append("private_key_pass", this.private_key);
      this.disabledBtn = true;
      try {
        await WidgetApiUrls.setCertificate(formdata, jwt);
        toast.success("Вы успешно добавили сертификат!");
        this.dialog_certificate = false;
      } finally {
        this.disabledBtn = false;
      }
    },
    showWidget() {
      if (this.iframe.length == 0) {
        toast.error("Подключите виджет");
        return;
      }
      this.show = !this.show;
    },
    async saveColors() {
      if (this.iframe.length == 0) {
        this.dialog_a = false;
        this.show = false;
        toast.error("Подключите виджет");
        return;
      }

      let jwt = localStorage.getItem("token");
      let CompanyWidgetCompanyWidget = {
        primary_color: this.primary_color,
        accent_color: this.accent_color,
        text_color: this.text_color,
        btn_text_color: this.text_btn_color,
        background_color: this.background_color
      };
      this.disabledBtn = true;
      try {
        let resp = await WidgetApiUrls.setColors(
          CompanyWidgetCompanyWidget,
          jwt
        );
        console.log(resp);
        toast.success("Успешно сохранилось!");
        this.company_data = resp;
        this.back_color = resp.background_color;
        this.prim_color = resp.primary_color;
        this.acc_color = resp.accent_color;
        this.txt_color = resp.text_color;
        this.txt_btn_color = resp.btn_text_color;
        localStorage.setItem("api_url", this.company_data.api_url);
        localStorage.setItem("id_company", this.company_data.company_id);
        let api_url = localStorage.getItem("api_url");
        this.iframe = `<script type="text\/javascript">
        localStorage.setItem("id_company", "${localStorage.getItem(
          "id_company"
        )}");
        localStorage.setItem("background", "${resp.background_color}"); 
        localStorage.setItem("primary", "${resp.primary_color}"); 
        localStorage.setItem("accent", "${resp.accent_color}"); 
        localStorage.setItem("text", "${resp.text_color}"); 
        localStorage.setItem("text_button", "${
          resp.btn_text_color
        }" ); <\/script>\
        <vue-widget title="Vuidget live example"><\/vue-widget>\n
        <script src="${api_url}\/widget\/app.js" type="text\/javascript"><\/script>\n
        <link rel="stylesheet" href="${api_url}\/widget\/app.css">`;
        this.dialog_a = false;
        this.show = false;
        localStorage.setItem("background", this.back_color);
        localStorage.setItem("primary", this.prim_color);
        localStorage.setItem("accent", this.acc_color);
        localStorage.setItem("text", this.txt_color);
        localStorage.setItem("text_button", this.txt_btn_color);
      } finally {
        this.disabledBtn = false;
      }
    },
    copy() {
      let inp = document.createElement("input");
      inp.id = "my_text";
      inp.style = {
        position: "absolute",
        top: "-100%"
      };
      inp.value = this.iframe;
      document.body.appendChild(inp);
      let textToCopy = document.querySelector("#my_text");
      textToCopy.select();
      document.execCommand("copy");
      inp.remove();
      toast.success("Скопировано в буфер обмена!");
    },
    copy_PartnerLink() {
      let inp = document.createElement("input");
      inp.id = "my_text";
      inp.style = {
        position: "absolute",
        top: "-100%"
      };
      inp.value = this.iframe2;
      document.body.appendChild(inp);
      let textToCopy = document.querySelector("#my_text");
      textToCopy.select();
      document.execCommand("copy");
      inp.remove();
      toast.success("Скопировано в буфер обмена!");
    },
    ShowPartner_link(){
      // window.location.href = this.iframe2
      window.open(this.iframe2, '_blank');

    }
  },
  computed:{
    GetCompanySlug() {
      return user.getters.getSlug()
    },
  }
};
</script>
