<template>
    <div class="access-restricted">
      <v-container class="text-center">
        <v-row>
          <v-col>
            <h2 class="headline">Ограниченный доступ</h2>
            <p>
              Вы находитесь в стартовом режиме приложения. Некоторые функции могут быть недоступны.
            </p>
            <p>
              Чтобы получить доступ ко всем возможностям, пожалуйста, обновите вашу подписку.
            </p>
            <p>
              Свяжитесь с нашей службой поддержки или перейдите в раздел "Мой аккаунт" для получения информации об обновлении.
            </p>
            <v-btn color="#FFA500" @click="testClick">Запросить доступ</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
import user from "../../../store/auth/index";
import toast from '../../../plugins/toast';
  export default {
    data: () => {
      return{
        test: "test"
      }
    },
    methods:{
      testClick(){
        // Тут должен быть запрос, к 
        if(this.test === "test"){
          console.log(user.state)
        }
        toast.success(this.test)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .access-restricted {
    margin-top: 50px;
  }
  
  .headline {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  </style>
  